import { getAllCountriesFromServer, getCourierListApi } from 'app/api/api';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_COURIER_LIST = 'GET_COURIER_LIST';

export function getAllCountries() {
	const request = getAllCountriesFromServer();
	return dispatch => {
		return request.then(response => {
			let countries = response?.data?.data ?? [];
			dispatch({ type: GET_ALL_COUNTRIES, data: countries });
		});
	};
}

export function getCourierList() {
	const request = getCourierListApi();
	return dispatch => {
		return request.then(response => {
			let couriers = response?.data?.data ?? [];
			dispatch({ type: GET_COURIER_LIST, data: couriers });
		});
	};
}
