import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React from 'react';

function RightSideLayout3() {
	return (
		<>
			<QuickPanel />
		</>
	);
}

export default RightSideLayout3;
