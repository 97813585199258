import axios from 'axios';
export const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	withCredentials: true
});
export const callApiUsingFunction = evt => {
	const { callUrl, method } = evt;

	let { headers } = evt;
	let data;
	if (evt.json) {
		headers = Object.assign({ 'Content-Type': 'application/json' }, headers);
		data = evt.json;
	} else {
		data = evt.body;
	}

	try {
		const response = Axios({
			method: method,
			url: callUrl,
			headers,
			data: data
		});
		return response;
	} catch (err) {
		new Promise((resolve, reject) => {
			return reject(err);
		});
	}
};
