export class UploaderDetails {
	uploading = 0;
	pending = 0;
	count = 0;
	done = 0;
	assetTypes = {};

	constructor(props) {
		this.assetTypes = props?.assetTypes ?? this.assetTypes;
		this.uploading = props?.uploading ?? this.uploading;
		this.pending = props?.pending ?? this.pending;
		this.count = props?.count ?? this.count;
		this.done = props?.done ?? this.done;
		this.fileName = props?.fileName ?? '';
	}

	addToTypes(type, state) {
		this.assetTypes[type] = state;
	}

	removeFromTypes(type) {
		delete this.assetTypes[type];
	}

	enQueue() {
		this.pending += 1;
		this.count += 1;
	}

	removeItem(type) {
		this.pending -= 1;
		this.count -= 1;
		this.removeFromTypes(type);
	}

	deQueue(type) {
		this.uploading += 1;
		this.pending -= 1;
		this.addToTypes(type, 'uploading');
	}

	finish(type) {
		this.uploading -= 1;
		this.done += 1;
		this.addToTypes(type, 'done');
	}

	addUploadingItem(type) {
		this.uploading += 1;
		this.count += 1;
		this.addToTypes(type, 'uploading');
	}

	addPendingItem(type) {
		this.pending += 1;
		this.count += 1;
		this.addToTypes(type, 'pending');
	}

	getUploadingItemsCount() {
		return Object.keys(this.uploading).length;
	}
}
