import { Table, TableBody, TableCell, TableHead, TableRow, LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { constants } from 'app/main/helpers/strings';
import React from 'react';

const rows = [
	{
		id: 'orderId',
		align: 'left',
		disablePadding: false,
		label: 'Order ID'
	},
	{
		id: 'File Name',
		align: 'left',
		disablePadding: false,
		label: 'File Name'
	},
	{
		id: 'assetType',
		align: 'left',
		disablePadding: false,
		label: 'Asset Type'
	},
	{
		id: 'progress',
		align: 'left',
		disablePadding: false,
		label: 'Progress'
	}
];

function UploadingTable() {
	const uploader = useSelector(({ fuse }) => fuse.uploader);
	const { uploading } = uploader;

	return (
		<Table size="small" aria-label="a dense table">
			<TableHead>
				<TableRow>
					{rows.map(row => (
						<TableCell key={row.id} align={row.align}>
							{row.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{Object.keys(uploading.items).map(orderId => {
					let fileType = orderId.includes('mesFilesUrl') ? 'mesFilesUrl' : 'prodFilesUrl';
					return Object.keys(uploading.items[orderId]).map((item, index) => {
						return (
							<TableRow key={index} className="cursor-none">
								<TableCell component="th" scope="row">
									{orderId.split('-')[0] ?? '-'}
								</TableCell>
								<TableCell component="th" scope="row">
									{uploader?.uploadDetails[orderId]?.fileName ?? ''}
								</TableCell>
								<TableCell>
									{constants.FILES_CASE_CREATION_MAP[fileType] ?? 'Production File'}
								</TableCell>
								<TableCell>
									<LinearProgress
										className="max-w-full"
										variant="determinate"
										value={parseInt(uploading.items[orderId][item].itemProgress)}
									/>
								</TableCell>
							</TableRow>
						);
					});
				})}
			</TableBody>
		</Table>
	);
}

export default UploadingTable;
