import FuseUtils from '@fuse/utils/FuseUtils';
// import { getCompanyDataFromServer, getLoggedInDataApi } from 'app/api/api';
import { login } from 'app/api/noTokenApi';
// import chatService from 'app/services/chatService';

import { Axios } from 'app/api/fetchFunction';
/* eslint-disable camelcase */

class SessionService extends FuseUtils.EventEmitter {
	init(dispatch) {
		this.dispatch = dispatch;
		this.setInterceptors(this);
		this.handleAuthentication();
		this.isLoggingOut = false;
	}

	setInterceptors = service => {
		Axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				if ([401, 403].includes(err?.response?.status || err?.status)) {
					// if you ever get an unauthorized response, logout the user
					if (service.isLoggingOut === false) {
						service.isLoggingOut = true;
						setTimeout(() => {
							service.isLoggingOut = false;
						}, 4000);
						service.emit('onAutoLogout', 'Session expired');
						service.setSession(null);
					}
					// just clear the session
					this.logout();
				}
				// return rejected promise
				return Promise.reject(err);
			}
		);
	};

	handleAuthentication = () => {
		const sessionId = this.getSessionId();
		if (!sessionId) {
			this.emit('onNoAccessToken');
			return;
		}
		if (this.isSessionValid(sessionId)) {
			this.setSession(sessionId);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
		}
	};

	signInWithEmailAndPassword = (username, password) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await login({ username, password });
				this.setUserData(data);
				this.setSession(1);

				const userObject = {
					role: 'admin',
					data: {
						displayName: data?.name || 'user',
						photoURL: 'assets/images/avatars/profile.jpg',
						email: data?.email || ''
					}
				};
				resolve(userObject);
			} catch (error) {
				reject(error);
			}
		});
	};

	signInWithToken = () => {
		try {
			const userJsonData = this.getUserData();
			let user = JSON.parse(userJsonData);
			return new Promise(async (resolve, reject) => {
				try {
					const userObject = {
						role: 'admin',
						data: {
							displayName: user?.name ?? 'user',
							photoURL: 'assets/images/avatars/profile.jpg',
							email: user?.email ?? ''
						}
					};
					resolve(userObject);
				} catch (error) {
					reject(error);
					this.logout();
					throw error;
				}
			});
		} catch (error) {
			console.log(error);
			this.logout();
		}
	};

	setSession = sessionId => {
		if (sessionId) {
			localStorage.setItem('SESSION_MANUFACTURING', sessionId);
		} else {
			localStorage.removeItem('SESSION_MANUFACTURING');
			localStorage.removeItem('USER_DATA');
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isSessionValid = sessionId => {
		if (!sessionId) {
			return false;
		}
		return true;
	};

	getSessionId = () => {
		return window.localStorage.getItem('SESSION_MANUFACTURING');
	};
	setUserData = data => {
		localStorage.setItem('USER_DATA', data ? JSON.stringify(data) : null);
	};
	getUserData = () => {
		return window.localStorage.getItem('USER_DATA');
	};
}

const instance = new SessionService();

export default instance;
