import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
// import { getCompanyDataFromServer, getLoggedInDataApi } from 'app/api/api';
import { login } from 'app/api/noTokenApi';

/* eslint-disable camelcase */
class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			// setAuthorization(access_token);
			if (this.isAuthTokenNotExpired()) {
				this.setSession(access_token);
				this.emit('onAutoLogin', true);
			} else {
				this.refreshToken();
			}
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	refreshToken = () => {
		// const ref_token = this.getRefToken();
		// refreshTokenApi({ refreshToken: ref_token })
		// 	.then(r => [
		// 		this.setRefAndExp(r.body.refreshToken, r.body.expiresIn),
		// 		setAuthorization(r.body.accessToken),
		// 		this.setSession(r.body.accessToken),
		// 		this.emit('onAutoLogin', true)
		// 	])
		// 	.catch(error => [this.setSession(null), this.emit('onAutoLogout', 'Invalid access_token')]);
	};

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			login({ username: email, password: password }).then(async response => {
				// await setAuthorization(response.data.accessToken);
				// const loggedInDetails = await getLoggedInDataApi().catch(error => reject(error));
				// const companyDetails = await getCompanyDataFromServer().catch(error => reject(error));
				// this.setRefAndExp(response.data.refreshToken, response.data.expiresIn);
				// this.setSession(response.data.accessToken);
				// const decodedToken = jwtDecode(response.data.accessToken);
				// const userObject = {
				// 	role: decodedToken.userType,
				// 	data: {
				// 		displayName: decodedToken.name || 'user',
				// 		photoURL: loggedInDetails.body.avatarUrl || 'assets/images/avatars/profile.jpg',
				// 		email: decodedToken.email || '',
				// 		userId: decodedToken.userId,
				// 		flagsMap: loggedInDetails.body.flagsMap
				// 	},
				// 	doctorData: companyDetails.body
				// };
				// resolve(userObject);
			});
		});
	};

	setRefAndExp = (RefToken, Exp) => {
		localStorage.setItem('JWT_EON_MANUFACTURING_EXP', new Date().getTime() / 1000 + Exp);
		localStorage.setItem('JWT_EON_MANUFACTURING_REF', RefToken);
	};

	signInWithToken = () => {
		// try {
		// 	const access_token = this.getAccessToken();
		// 	let decodedToken = jwtDecode(access_token);
		// 	return new Promise(async (resolve, reject) => {
		// 		const companyDetails = await getCompanyDataFromServer().catch(error => {
		// 			reject(error);
		// 			this.logout();
		// 			throw error;
		// 		});
		// 		const loggedInDetails = await getLoggedInDataApi().catch(error => {
		// 			reject(error);
		// 			this.logout();
		// 			throw error;
		// 		});
		// 		const userObject = {
		// 			role: decodedToken.userType,
		// 			data: {
		// 				displayName: decodedToken.name || 'user',
		// 				photoURL: loggedInDetails.body.avatarUrl || 'assets/images/avatars/profile.jpg',
		// 				email: decodedToken.email || '',
		// 				userId: decodedToken.userId,
		// 				flagsMap: loggedInDetails.body.flagsMap
		// 			},
		// 			doctorData: companyDetails && companyDetails.body
		// 		};
		// 		resolve(userObject);
		// 	});
		// } catch (error) {
		// 	console.log(error);
		// 	this.logout();
		// }
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('JWT_EON_MANUFACTURING', access_token);
		} else {
			localStorage.removeItem('JWT_EON_MANUFACTURING');
			localStorage.removeItem('JWT_EON_MANUFACTURING_REF');
			localStorage.removeItem('JWT_EON_MANUFACTURING_EXP');
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		return true;
	};

	isAuthTokenNotExpired = () => {
		try {
			const expireDate = this.getExpDate();
			const currentTime = new Date().getTime() / 1000;
			if (expireDate - 20000 < currentTime) {
				//on token expire time - 20000 secounds
				console.warn('access token expired');
				return false;
			}

			return true;
		} catch (error) {
			console.log(error);
		}
	};
	getAccessToken = () => {
		return window.localStorage.getItem('JWT_EON_MANUFACTURING');
	};
	getRefToken = () => {
		return window.localStorage.getItem('JWT_EON_MANUFACTURING_REF');
	};
	getExpDate = () => {
		return window.localStorage.getItem('JWT_EON_MANUFACTURING_EXP');
	};
}

const instance = new JwtService();

export default instance;
