import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router';

const CusotmersConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/orders',
			component: React.lazy(() => import('./preloader')),
			routes: [
				{
					path: '/orders',
					exact: true,
					component: React.lazy(() => import('./orders/orders'))
				},
				{
					path: '/orders/:orderId/:tabName',
					exact: true,
					component: React.lazy(() => import('app/main/orders/orderDetails/index'))
				},
				{
					path: '/orders',
					component: () => <Redirect to="/orders" />
				}
			]
		}
	]
};

export default CusotmersConfig;
