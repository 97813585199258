import React from 'react';
import './splashScreen.css';
function FuseSplashScreen() {
	// const customReducer = useSelector(({ fuse }) => fuse.customReducer);
	return (
		<div id="root" className="flex">
			<div id="fuse-splash-screen">
				<div className="center">
					<div className="logo"></div>

					<div className="spinner-wrapper">
						<div className="spinner">
							<div className="inner">
								<div className="gap"></div>
								<div className="left">
									<div className="half-circle"></div>
								</div>
								<div className="right">
									<div className="half-circle"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(FuseSplashScreen);

// <div id="fuse-splash-screen">
// 	<div className="center">
// 		<div className="logo">
// 			<img width="128" src={customReducer.imagePath} alt="logo" />
// 		</div>
// 		<div className="spinner-wrapper">
// 			<div className="spinner">
// 				<div className="inner">
// 					<div className="gap" />
// 					<div className="left">
// 						<div className="half-circle" />
// 					</div>
// 					<div className="right">
// 						<div className="half-circle" />
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>
// </div>
