import { MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import React from 'react';
import QueueTable from './queueTable';
import UploadingTable from './uploadingTable';

function Interface(props) {
	const uploader = useSelector(({ fuse }) => fuse.uploader);
	const { uploading, pending } = uploader;
	return (
		<div className="max-h-320">
			<Typography color="primary" className="p-4 font-bold">
				Uploading files
			</Typography>
			{uploading.length >= 1 ? <UploadingTable /> : <MenuItem disabled>no items</MenuItem>}
			<Typography color="primary" className="p-4 font-bold">
				In Queue
			</Typography>
			{pending.length >= 1 ? <QueueTable uploader={uploader} /> : <MenuItem disabled>no items</MenuItem>}
		</div>
	);
}

export default Interface;
