import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import login from 'app/main/login/LoginConfig';
import ForgetPasswordConfig from 'app/main/forgetpassword/forgetPasswordConfig';
import SetPasswordConfig from 'app/main/setpassword/setPasswordConfig';
import OrdersConfig from 'app/main/orders/OrdersConfig';
const routeConfigs = [OrdersConfig, login, ForgetPasswordConfig, SetPasswordConfig];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/orders" />
	}
];

export default routes;
