export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export function customUpdateUserData(newdata) {
	return dispatch => {
		dispatch({
			type: UPDATE_USER_DATA,
			payload: newdata
		});
	};
}
