import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import * as userActions from 'app/auth/store/actions';
// import jwtService from 'app/services/jwtService';
import sessionService from 'app/services/sessionService';
import * as Actions from 'app/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onMessage } from '../api/api';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		onMessage(options => {
			this.props.showMessage({ message: options });
		});
		return Promise.all([this.checkSession()])
			.then(() => {
				this.setState({ waitAuthCheck: false });
			})
			.catch(err => {
				console.log('Error occured ', err);
			});
	}

	checkSession = () =>
		new Promise((resolve, reject) => {
			sessionService.on('onAutoLogin', () => {
				this.props.showMessage({ message: 'Logging Successfuly' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				sessionService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);
						resolve();
					})
					.catch(error => {
						reject();
					});
			});

			sessionService.on('onAutoLogout', message => {
				if (message && typeof message === 'string') {
					this.props.showMessage({ message });
				}
				this.props.logout();
				resolve();
			});

			sessionService.on('onNoAccessToken', message => {
				if (message && typeof message === 'string') {
					this.props.showMessage({ message });
				}
				this.props.logout();
				resolve();
			});
			sessionService.init(this.props.dispatch);

			return Promise.resolve();
		});

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: userActions.logoutUser,
			setUserData: userActions.setUserData,
			setUserDataAuth0: userActions.setUserDataAuth0,
			//setUserDataFirebase: userActions.setUserDataFirebase,
			showMessage: Actions.showMessage,
			hideMessage: Actions.hideMessage,
			dispatch: dispatch => dispatch
		},
		dispatch
	);
}

const mapStateToProps = ({ fuse }) => {
	return { fuse };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
