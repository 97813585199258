import { callApiUsingFunction } from './fetchFunction';
import { onMessageListeners } from './api';

function createRequest({ method }) {
	return async function request({ path, headers, json, body, callback, query }) {
		let qs = '';
		const params = [];

		for (let key in json) {
			if (json[key] === '') {
				delete json[key];
			}
		}
		for (let key in query) {
			if (query[key] === '') {
				delete query[key];
			}
		}

		if (query) {
			qs = '?';
			for (const queryParam in query) {
				params.push(`${queryParam}=${query[queryParam]}`);
			}
			qs += params.join('&');
		}

		return new Promise(async (resolve, reject) => {
			let notification;
			let response;
			let errorHandling;
			try {
				response = await callApiUsingFunction({
					callUrl: `${path}${qs}`,
					method,
					headers: headers,
					json,
					body
				});
				notification = response?.data?.message
					? response?.data?.message || undefined
					: response.data?.msg
					? response.data?.msg || undefined
					: undefined;
			} catch (error) {
				let checkResponseText = error?.request?.responseText;
				let parseMessage =
					checkResponseText && JSON.parse(checkResponseText)
						? JSON.parse(checkResponseText)
						: error?.request?.responseText;
				if (parseMessage && parseMessage?.errors && Array.isArray(parseMessage?.errors?.username)) {
					notification = parseMessage.errors?.username[0];
				} else if (parseMessage && parseMessage?.message) {
					notification = parseMessage?.message;
				} else if (error.response) {
					const handleError = error.response?.data;

					if (handleError) {
						if (Array.isArray(handleError?.error)) {
							notification = handleError?.error[0]?.msg || undefined;
						} else {
							notification = handleError?.message || undefined;
						}
					}
					notification = parseMessage?.message
						? parseMessage?.message
						: error?.response?.message
						? error?.response?.message
						: error?.response?.data?.message
						? error?.response?.data?.message
						: 'An error occurred, please try again';
				} else {
					/**@@ That means might be the error from the request or junt an error happened */
					/** error.request is an instance of XMLHttpRequest in the browser and an instance of
					 http.ClientRequest in node.js */
					/**
					 * If not error.request, that means somthing happened in setting up the request that triggered an 'Error'
					 */
					notification = 'An error occurred, please try again';
				}
				error.message = notification;
				errorHandling = error;
			} finally {
				onMessageListeners.forEach(callback => {
					if (notification && typeof notification === 'string') {
						callback(`${notification}`);
					}
				});

				if (response?.status && response?.status < 400) {
					return resolve(response);
				} else {
					return reject(errorHandling);
				}
			}
		});
	};
}

export const api = {
	v1: {
		post: createRequest({ method: 'post' }),
		get: createRequest({ method: 'get' }),
		put: createRequest({ method: 'put' }),
		delete: createRequest({ method: 'delete' })
	}
};

function setPasswordApi(json) {
	return api.v1.put({ path: `/user/setPassword`, json: json });
}

function forgetPasswordApi(json) {
	return api.v1.post({ path: `/user/forgetPassword`, json: json });
}

function forgetPasswordEmailApi(json) {
	return api.v1.post({ path: `/user/changePassword`, json: json });
}

function login(values) {
	return api.v1.post({ path: '/auth', json: values });
}
export { setPasswordApi, forgetPasswordApi, forgetPasswordEmailApi, login };
