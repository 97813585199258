import sessionService from 'app/services/sessionService';
import * as UserActions from './user.actions';
import * as GlobalActions from 'app/store/actions';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({ username, password }) {
	return dispatch => {
		sessionService
			.signInWithEmailAndPassword(username, password)
			.then(user => {
				dispatch(UserActions.setUserData(user));

				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				console.log({ error });

				dispatch(GlobalActions.showMessage({ message: error?.message ?? 'Somthing went wrong!' }));
				return dispatch({
					type: LOGIN_ERROR,
					payload: error
				});
			});
	};
}
