import auth from 'app/auth/store/reducers';
import { combineReducers } from 'redux';
import fuse from './fuse';
// import { adminReducer, packageLanguageSlice } from '@eon-dental/advanced-modules/dist/rollup-export';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		// adminModules: adminReducer.default,
		// packageLanguage: packageLanguageSlice.reducer,
		...asyncReducers
	});

export default createReducer;
