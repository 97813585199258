/**
 * Authorization Roles
 */

const authRoles = {
	admin: ['admin'],
	onlyGuest: []
};

export default authRoles;
