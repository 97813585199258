const initialState = {
	uploading: { items: {}, length: 0 },
	pending: [],
	uploadDetails: {},
	uploadBlocked: false,
	length: 0
};

const customReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_APP_CONTROL_UPLOADING_INIT_ITEM': {
			return {
				...action.uploader
			};
		}
		case 'SET_APP_CONTROL_UPLOADING_EDIT_ITEM': {
			return {
				...state,
				uploading: {
					...state.uploading,
					items: {
						...state.uploading.items,
						[action.data.orderId]: action.data.name
							? {
									[action.data.name]: {
										...state.uploading.items[action.data.orderId]?.[action.data.name],
										itemProgress: action.data.itemProgress
									}
							  }
							: {}
					}
				}
			};
		}
		case 'SET_APP_CONTROL_UPLOADING_DELETE_ITEM_QUEUE': {
			return { ...action.uploader };
		}
		case 'SET_APP_CONTROL_UPLOADING_REMOVE_ITEM': {
			return { ...action.uploader };
		}
		default: {
			return state;
		}
	}
};
export default customReducer;
