//colors for status in orders
const STATUSCOLOR = [
	{
		id: 1,
		name: 'PENDING',
		color: 'bg-yellow-800 text-white'
	},
	{
		id: 2,
		name: 'READY_FOR_MANUFACTURING',
		color: 'bg-purple-300 text-white'
	},
	{
		id: 3,
		name: 'PROCESSING',
		color: 'bg-blue-600 text-white'
	},
	{
		id: 4,
		name: 'MANUFACTURING',
		color: 'bg-blue-800 text-white'
	},
	{
		id: 5,
		name: 'READY_FOR_AWB',
		color: 'bg-purple-400 text-white'
	},
	{
		id: 6,
		name: 'READY_FOR_SHIPPING',
		color: 'bg-purple-400 text-white'
	},
	{
		id: 7,
		name: 'SHIPPED',
		color: 'bg-green-400 text-white'
	},

	{
		id: 9,
		name: 'CANCELED',
		color: 'bg-red-700 text-white'
	},
	{
		id: 10,
		name: 'FAILED_TO_MANUFACTURING',
		color: 'bg-red-400 text-white'
	},
	{
		id: 11,
		name: 'DELIVERED',
		color: 'bg-green-800 text-white'
	},

	{
		id: 12,
		name: 'BLOCKED',
		color: 'bg-black text-white'
	},

	{
		id: 13,
		name: 'REORDERED',
		color: 'bg-purple-200 text-white'
	}
];

const ORDER_STATUS_MAP = {
	PENDING: 'Pending',
	READY_FOR_MANUFACTURING: 'Ready for manufacturing',
	PROCESSING: 'Processing',
	MANUFACTURING: 'Manufacturing',
	READY_FOR_AWB: 'Ready for AWB',
	READY_FOR_SHIPPING: 'Ready for shipping',
	SHIPPED: 'Shipped',
	CANCELED: 'Canceled',
	FAILED_TO_MANUFACTURING: 'Failed to manufacture',
	DELIVERED: 'Delivered',
	BLOCKED: 'Blocked',
	REORDERED: 'Reordered'
};

const ORDER_STATUS_SUGGESTIONS = [
	{
		label: 'Pending',
		value: 'PENDING'
	},
	{
		label: 'Ready for manufacturing',
		value: 'READY_FOR_MANUFACTURING'
	},
	{
		label: 'Failed to manufacture',
		value: 'FAILED_TO_MANUFACTURING'
	},

	{
		label: 'Processing',
		value: 'PROCESSING'
	},
	{
		label: 'Manufacturing',
		value: 'MANUFACTURING'
	},
	{
		label: 'Ready for AWB',
		value: 'READY_FOR_AWB'
	},
	{
		label: 'Ready for shipping',
		value: 'READY_FOR_SHIPPING'
	},
	{
		label: 'Shipped',
		value: 'SHIPPED'
	},
	{
		label: 'Canceled',
		value: 'CANCELED'
	},
	{
		label: 'Delivered',
		value: 'DELIVERED'
	},
	{
		label: 'Blocked',
		value: 'BLOCKED'
	},
	{
		label: 'Reordered',
		value: 'REORDERED'
	}
];

const ORDER_TYPE_OPTIONS = [
	{ label: 'Standard', value: 'STANDARD' },
	{ label: 'Remake', value: 'REMAKE' },
	{ label: 'Refinement', value: 'REFINEMENT' },
	{ label: 'Adjustment', value: 'ADJUSTMENT' },
	{ label: 'Continuation', value: 'CONTINUATION' },
	{ label: 'Replacement', value: 'REPLACEMENT' },
	{ label: 'Overcorrection', value: 'OVERCORRECTION' }
];
const ORDER_TYPE_MAP_OPTIONS = {
	STANDARD: 'Standard',
	REMAKE: 'Remake',
	REFINEMENT: 'Refinement',
	ADJUSTMENT: 'Adjustment',
	CONTINUATION: 'Continuation',
	REPLACEMENT: 'Replacement',
	OVERCORRECTION: 'Overcorrection'
};

const PRODUCT_TYPE_OPTIONS = [
	{ label: 'Aligner', value: 'ALIGNER' },
	{ label: 'Retainer', value: 'RETAINER' },
	{ label: 'Whitening kit', value: 'WHITENING_KIT' },
	{ label: 'Night Guard', value: 'NIGHT_GUARD' }
];

const PRODUCT_TYPE_MAP_OPTIONS = {
	ALIGNER: 'Aligner',
	RETAINER: 'Retainer',
	WHITENING_KIT: 'Whitening kit',
	NIGHT_GUARD: 'Night Guard'
};
const CANCEL_ORDER_REASON_OPTIONS = [
	{ label: 'Manufacturing defect reported by client', value: 'MANUFACTURING_DEFECT' },
	{ label: 'Client Request', value: 'CLIENT_REQUEST' }
];

const CANCEL_MAP_OPTIONS = {
	CLIENT_REQUEST: 'Client Request',
	MANUFACTURING_DEFECT: 'Manufacturing defect reported by client'
};
const CUTLINE_MAP_OPTIONS = {
	STRAIGHT_CUT: 'Straight Cut',
	SCALLOPED: 'Scalloped',
	SEMI_SCALLOPED: 'Semi Scalloped',
	OTHER: 'Other'
};

const ALLOW_STATUSES_TO_BE_CHECKED = [
	'PENDING',
	'MANUFACTURING',
	'READY_FOR_AWB',
	'READY_FOR_SHIPPING',
	'FAILED_TO_MANUFACTURING',
	'SHIPPED'
];
const CHECKBOX_BUTTON_MAP = {
	PENDING: 'Ready for manufacturing',
	FAILED_TO_MANUFACTURING: 'Ready for manufacturing',
	MANUFACTURING: 'Ready for AWB',
	READY_FOR_AWB: 'Ready for shipping',
	READY_FOR_SHIPPING: 'Shipped',
	SHIPPED: 'Delivered'
};
const STATUS_ACTION_FOR_BULK_UPDATE = {
	PENDING: 'readyForManufacturing',
	MANUFACTURING: 'readyForAWB',
	READY_FOR_AWB: 'readyForShipping',
	READY_FOR_SHIPPING: 'shipped',
	FAILED_TO_MANUFACTURING: 'readyForManufacturing',
	SHIPPED: 'delivered'
};
const DEFAULT_STATUS_COMPARISON = 'READY_FOR_AWB';
const SHIPPED_STATUS_COMPARISON = 'READY_FOR_SHIPPING';
const PENDING_STATUS_COMPARISON = 'PENDING';
const FAILED_TO_MANUFACTURE_STATUS_COMPARISON = 'FAILED_TO_MANUFACTURING';

const FILES_CASE_CREATION_MAP = {
	prodFilesUrl: 'Production File',
	mesFilesUrl: ' MES File'
};
const SOURCE_APP = {
	EON_MFG: 'eon-mfg',
	EON_ACCESS: 'eon-access'
};

const UPDATE_SHIPPING_ADDRESS_STATUSES_MAP_OPTIONS = [
	'PENDING',
	'READY_FOR_MANUFACTURING',
	'PROCESSING',
	'MANUFACTURING',
	'READY_FOR_AWB'
];
const CANCEL_ORDER_MAP_OPTIONS = ['PENDING', 'READY_FOR_MANUFACTURING', 'PROCESSING'];
const HAS_PONTICS_OPTIONS_OLD_CASES = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false }
];

const HAS_PONTICS_OPTIONS = {
	false: 'No',
	true: 'Yes'
};

const UPDATE_HAS_PONTICS_STATUSES = ['PENDING', 'READY_FOR_MANUFACTURING'];
const UPDATE_PREFERENCES = ['PENDING', 'FAILED_TO_MANUFACTURING'];
const HAS_PONTICS_VALUES = { true: 'Yes', false: 'No' };
const DELIVERED_STATUSES = ['SHIPPED'];
const NOT_EXPEDITE_ORDER_OPTIONS = ['SHIPPED', 'DELIVERED', 'BLOCKED'];
const OWNER_TYPE_OPTIONS = {
	clientOffices: 'Client Offices',
	patient: 'Patient',
	doctor: 'Doctor',
	clinic: 'Clinic'
};
const OFFSETS_VALUES = [
	{ value: 0, label: '0 mm' },
	{ value: 0.5, label: '0.5 mm' },
	{ value: 1, label: '1 mm' },
	{ value: -1, label: 'Zenyum Offset' }
];
const TRIMLINES = [
	{ label: 'Scalloped', value: 'SCALLOPED' },
	{ label: 'Straight', value: 'STRAIGHT_CUT' },
	{ label: 'Zenyum Cutline', value: 'ZENYUM_CUTLINE' }
];
const ALIGNERS_MATERIALS = [{ label: 'Zendura FLX', value: 'ZENDURA_FLX' }];
const RETAINERS_MATERIALS = [
	{ label: 'Zendura FLX', value: 'ZENDURA_FLX' },
	{ label: 'Zendura A', value: 'ZENDURA_A' },
	{ label: 'Duran Plus', value: 'DURAN_PLUS' }
];
const INDEXING_TYPES = [
	{ label: 'No indexing', value: 'noIndexing' },
	{ label: 'Light indexing', value: 'lightIndexing' },
	{ label: 'Medium indexing', value: 'mediumIndexing' },
	{ label: 'Heavy indexing', value: 'heavyIndexing' }
];

const IMPRESSION_RETAINER_METHOD = {
	useLastStep:"use Last Step" , 
	uploadNewImpression:"upload New Impression"
}
const IMPRESSION_RETAINER_TYPE = {
	digitalUpload:"digital Upload" , 
	digitalThirdParty :"digital Third Party",
	physical:"physical", 
	"3shapeScanner":"3shape Scanner" , 
	meditScanner:"medit Scanner"

}
const NO_ACTIONS_STATUSES = ['BLOCKED'];

const constants = {
	STATUSCOLOR,
	ORDER_STATUS_MAP,
	ORDER_STATUS_SUGGESTIONS,
	ORDER_TYPE_OPTIONS,
	PRODUCT_TYPE_OPTIONS,
	PRODUCT_TYPE_MAP_OPTIONS,
	CANCEL_ORDER_REASON_OPTIONS,
	CUTLINE_MAP_OPTIONS,
	ORDER_TYPE_MAP_OPTIONS,
	CANCEL_MAP_OPTIONS,
	HAS_PONTICS_OPTIONS_OLD_CASES,
	ALLOW_STATUSES_TO_BE_CHECKED,
	CHECKBOX_BUTTON_MAP,
	STATUS_ACTION_FOR_BULK_UPDATE,
	DEFAULT_STATUS_COMPARISON,
	SHIPPED_STATUS_COMPARISON,
	SOURCE_APP,
	FILES_CASE_CREATION_MAP,
	UPDATE_SHIPPING_ADDRESS_STATUSES_MAP_OPTIONS,
	CANCEL_ORDER_MAP_OPTIONS,
	PENDING_STATUS_COMPARISON,
	FAILED_TO_MANUFACTURE_STATUS_COMPARISON,
	HAS_PONTICS_OPTIONS,
	UPDATE_HAS_PONTICS_STATUSES,
	HAS_PONTICS_VALUES,
	DELIVERED_STATUSES,
	NOT_EXPEDITE_ORDER_OPTIONS,
	OWNER_TYPE_OPTIONS,
	OFFSETS_VALUES,
	TRIMLINES,
	ALIGNERS_MATERIALS,
	RETAINERS_MATERIALS,
	UPDATE_PREFERENCES,
	INDEXING_TYPES,
	NO_ACTIONS_STATUSES, 
	IMPRESSION_RETAINER_METHOD ,
	IMPRESSION_RETAINER_TYPE
};

export { constants };
