import { Badge, IconButton } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import React, { useState } from 'react';
import Interface from './interface';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { useSelector } from 'react-redux';

function Uploader(props) {
	// const classes = customAnimation();
	const isUploading = useSelector(({ fuse }) => fuse.uploader.uploadBlocked);
	const uploadLength = useSelector(({ fuse }) => fuse.uploader.length);
	const [userMenu, setUserMenu] = useState(null);
	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};
	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<FuseScrollbars>
				<IconButton onClick={userMenuClick} aria-label="show 17 new notifications" color="primary" data-testid="topBarUploadButton">
					{isUploading ? (
						<Badge color="secondary" badgeContent={uploadLength ?? ''}>
							<Icon>cloud_upload</Icon>
						</Badge>
					) : (
						<Badge color="secondary">
							<Icon>cloud_upload</Icon>
						</Badge>
					)}
				</IconButton>

				<Popover
					open={Boolean(userMenu)}
					anchorEl={userMenu}
					onClose={userMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					classes={{
						paper: 'p-8'
					}}
				>
					{Boolean(userMenu) && <Interface />}
				</Popover>
			</FuseScrollbars>
		</>
	);
}

export default React.memo(Uploader);
