import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { constants } from 'app/main/helpers/strings';
import * as UploaderActions from 'app/store/actions/fuse/custom.uploader.actions';
import React from 'react';
import Icon from '@material-ui/core/Icon';

const rows = [
	{
		id: 'orderId',
		align: 'left',
		disablePadding: false,
		label: 'Order ID'
	},
	{
		id: 'File Name',
		align: 'left',
		disablePadding: false,
		label: 'File Name'
	},
	{
		id: 'assetType',
		align: 'left',
		disablePadding: false,
		label: 'Asset Type'
	},
	{
		id: 'discard',
		align: 'left',
		disablePadding: false,
		label: 'Cancel'
	}
];

function QueueTable() {
	const dispatch = useDispatch();
	const uploader = useSelector(({ fuse }) => fuse.uploader);
	const { pending } = uploader;
	return (
		<Table size="small" aria-label="a dense table">
			<TableHead>
				<TableRow>
					{rows.map(row => (
						<TableCell key={row.id} align={row.align}>
							{row.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{pending.map((item, index) => {
					let fileType = item?.assetType?.includes('mesFilesUrl') ? 'mesFilesUrl' : 'prodFilesUrl';
					return (
						!item.discarded && (
							<TableRow key={index} className="cursor-none">
								<TableCell component="th" scope="row">
									{item.orderId.split('-')[0] ?? '-'}
								</TableCell>
								<TableCell component="th" scope="row">
									{uploader.uploadDetails[item.orderId].fileName}
								</TableCell>
								<TableCell>
									{constants.FILES_CASE_CREATION_MAP[fileType] ?? 'Production File'}
								</TableCell>
								<TableCell>
									<IconButton
										onClick={() =>
											dispatch(
												UploaderActions.deleteItemFromQueue(item, {
													key: index
												})
											)
										}
										className="text-10 m-0 p-0"
									>
										<Icon color="error">close</Icon>
									</IconButton>
								</TableCell>
							</TableRow>
						)
					);
				})}
			</TableBody>
		</Table>
	);
}

export default React.memo(QueueTable);
