import { UploaderDetails } from 'app/main/helpers/uploader/uploaderItemDetails';
import { showMessage } from './message.actions';

export const SET_APP_CONTROL_UPLOADING_INIT_ITEM = 'SET_APP_CONTROL_UPLOADING_INIT_ITEM';
export const SET_APP_CONTROL_UPLOADING_ADD_ITEM = 'SET_APP_CONTROL_UPLOADING_ADD_ITEM';
export const SET_APP_CONTROL_UPLOADING_EDIT_ITEM = 'SET_APP_CONTROL_UPLOADING_EDIT_ITEM';
export const SET_APP_CONTROL_UPLOADING_REMOVE_ITEM = 'SET_APP_CONTROL_UPLOADING_REMOVE_ITEM';
export const SET_APP_CONTROL_UPLOADING_DELETE_ITEM_QUEUE = 'SET_APP_CONTROL_UPLOADING_DELETE_ITEM_QUEUE';

const numberOfSyncUploads = 3;

const initAsset = data => {
	return { [data.key]: { orderId: data.orderId, itemProgress: 0 } };
};

export function initUpload({ callback: functionCallback, orderId, assetType, fileName }) {
	return (dispatch, getState) => {
		const { uploader } = getState().fuse;
		const { uploadDetails, uploading, pending } = uploader;

		switch (uploadDetails[orderId]?.assetTypes?.[assetType]) {
			case 'pending':
				return dispatch(showMessage({ message: 'Same type is in queue', variant: 'warning' }));

			case 'uploading':
				return dispatch(showMessage({ message: 'Same type is being uploaded', variant: 'warning' }));
		}

		uploader.uploadBlocked = true;
		uploader.length += 1;

		const uploaderDetails = new UploaderDetails({
			uploading: uploadDetails[orderId]?.uploading,
			pending: uploadDetails[orderId]?.pending,
			count: uploadDetails[orderId]?.count,
			assetTypes: uploadDetails[orderId]?.assetTypes,
			fileName
		});

		uploadDetails[orderId] = uploaderDetails;

		if (uploading.length < numberOfSyncUploads) {
			uploading.length += 1;

			uploaderDetails.addUploadingItem(assetType);

			uploading.items[orderId] = initAsset({ key: assetType, orderId });

			functionCallback();
		} else {
			pending.push({
				orderId,
				assetType,
				function: functionCallback
			});

			uploaderDetails.addPendingItem(assetType);
		}

		dispatch({
			type: SET_APP_CONTROL_UPLOADING_INIT_ITEM,
			uploader
		});
	};
}

export function deleteItemFromQueue(data, key) {
	return async (dispatch, getState) => {
		const { uploader } = getState().fuse;
		const { uploadDetails, pending, uploading } = uploader;

		const uploaderDetails = new UploaderDetails(uploadDetails[data.orderId]);
		uploadDetails[data.orderId] = uploaderDetails;

		uploader.length -= 1;
		pending.splice(key.key, 1);

		uploaderDetails.removeItem(Object.keys(uploadDetails[data.orderId].assetTypes)[key.key]); //type send

		if (uploaderDetails.count === uploaderDetails.done) {
			delete uploading.items[data.orderId];
			delete uploadDetails[data.orderId];
		}

		dispatch({
			type: SET_APP_CONTROL_UPLOADING_DELETE_ITEM_QUEUE,
			uploader
		});
	};
}

export function removeUploadItem(data) {
	return (dispatch, getState) => {
		const { uploader } = getState().fuse;
		const { pending, uploading, uploadDetails } = uploader;
		if (uploader.pending.length > 0 && !!uploader.pending[0]) {
			const pendingItemDetails = new UploaderDetails(uploadDetails[uploader.pending[0].orderId]);

			pendingItemDetails.deQueue(uploader.pending[0].assetType);

			uploadDetails[uploader.pending[0].orderId] = pendingItemDetails;

			uploading.length += 1;

			pending[0].function();

			pending.shift();
		}

		const details = new UploaderDetails(uploadDetails[data.orderId]);
		details.finish();
		uploadDetails[data.orderId] = details;

		if (uploadDetails[data.orderId].pending === 0 && uploadDetails[data.orderId].uploading === 0) {
			delete uploading?.items[data.orderId];
			delete uploadDetails[data.orderId];
		} else {
			delete uploading?.items[data.orderId][data.name];
		}

		uploader.length -= 1;
		uploading.length -= 1;

		if (uploading.length === 0) {
			uploader.uploadBlocked = false;
		}

		dispatch({
			type: SET_APP_CONTROL_UPLOADING_REMOVE_ITEM,
			uploader
		});
	};
}

export function editUpload(data) {
	return {
		type: SET_APP_CONTROL_UPLOADING_EDIT_ITEM,
		data
	};
}
